import { useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Link from "next/link";
import {
  MdOutlineDashboardCustomize,
  MdKeyboardArrowRight,
  MdPersonAddAlt,
  MdPowerSettingsNew,
  MdOutlineShoppingBag,
  MdOutlineLiveHelp,
  MdArrowDownward,
  MdArrowDropDown,
} from "react-icons/md";
import { Blob_Base_Url } from "../../utils/static";
import { BsBookmarkDash } from "react-icons/bs";
import { useSelector } from "react-redux";
import { FiUser } from "react-icons/fi";
import { IKImage } from "imagekitio-react";

const NavUser = () => {
  const [show, setShow] = useState(false);
  const { authenticated, name, email } = useSelector((s) => s.auth);
  console.log(authenticated);

  return (
    <Link href={authenticated ? "/account" : "/signin"}>
      <div className="nav-user" role="button">
        <p className="font-sm text-mute mb-0">
          {authenticated ? `Hi, ${name}` : "Hello,Sign in"}
        </p>
        <p className="mb-0 font-lg">
          Account & More <MdArrowDropDown size={20} />
        </p>
        <div className="menu">
          <Menu authenticated={authenticated} name={name} email={email} />
        </div>
      </div>
    </Link>
  );

  // return (
  //   <>
  //     <div className="nav-user">
  //       <Dropdown
  //         className="custom-dropdown"
  //         onMouseLeave={() => setShowDropdown(false)}
  //         onMouseOver={() => setShowDropdown(true)}
  //         show={showDropdown}
  //       >
  //         <Dropdown.Toggle
  //           variant={`transparent bg-white p-2 ${
  //             showDropdown
  //               ? auth
  //                 ? "rounded-circle"
  //                 : "shadow-lg rounded-circle"
  //               : "shadow-none"
  //           }`}
  //         >
  //           {auth.authenticated ? (
  //             <div className="rounded overflow-hidden">
  //               <IKImage
  //                 transformation={[
  //                   {
  //                     height: 30,
  //                     width: 30,
  //                     v: Math.random(),
  //                     crop: "cm-pad_resize",
  //                   },
  //                 ]}
  //                 urlEndpoint={Blob_Base_Url}
  //                 path={"profiles/default.jpeg"}
  //                 className="bg-white h-auto w-100"
  //               />
  //             </div>
  //           ) : (
  //             <FiUser className="fs-4" />
  //           )}
  //         </Dropdown.Toggle>

  //         <Dropdown.Menu>
  //           <Menu {...auth} />
  //         </Dropdown.Menu>
  //       </Dropdown>
  //     </div>
  //   </>
  // );
};

const Menu = ({ authenticated, name, email }) => (
  <div>
    {!authenticated ? (
      <div className="bg-dark px-3">
        <h2 className="text-white fs-6 pt-5 pb-3">
          Signup with us to unlock all features! & Stay updated with latest
          offers.
        </h2>
        <Link href="/signin">
          <Button variant="light w-100 rounded-8 fw-bold mb-4">SIGN IN</Button>
        </Link>
      </div>
    ) : (
      <div className="bg-primary px-3">
        <div className="row align-items-center pt-4 mb-3">
          <div className="col-md-3">
            <IKImage
              transformation={[
                {
                  height: 100,
                  width: 100,
                  v: Math.random(),
                  crop: "cm-pad_resize",
                },
              ]}
              urlEndpoint={Blob_Base_Url}
              path={"profiles/default.jpeg"}
              className="bg-white h-auto w-100 rounded-circle"
            />
          </div>
          <div className="col-md-9">
            <h2 className="text-white fs-6 mb-0">{name}</h2>
            <p className="text-truncate text-white mb-0">{email}</p>
          </div>
        </div>
        <Link href="/account">
          <Button variant="light w-100 rounded-8 fw-bold mb-4">Account</Button>
        </Link>
      </div>
    )}
    <div className="menu-items">
      <ul className="mb-0 p-0">
        {Items(authenticated).map((x) => (
          <li>
            <Link href={x.href}>
              <a href={x.href}>
                {x.icon}
                <span>{x.title}</span>
                <MdKeyboardArrowRight className="ms-0 text-grey" />
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const Items = (auth, name) =>
  auth
    ? [
        {
          icon: <MdOutlineShoppingBag />,
          title: "My Orders",
          href: "/account/order",
        },
        {
          icon: <MdOutlineDashboardCustomize />,
          title: "My Requests",
          href: "/account/request",
        },
        {
          icon: <MdPowerSettingsNew />,
          title: "Log Out",
          href: "https://api-main.dealsonopenbox.com/Signout",
        },
        {
          icon: <MdOutlineLiveHelp />,
          title: "Need Help",
          href: "/support",
        },
      ]
    : [
        {
          icon: <BsBookmarkDash />,
          title: "WishList",
          href: "/wishlist",
        },
        {
          icon: <MdPersonAddAlt />,
          title: "Register",
          href: "/signup",
        },
        {
          icon: <MdOutlineLiveHelp />,
          title: "Need Help",
          href: "/support",
        },
      ];

export default NavUser;
