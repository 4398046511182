import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { Container, Dropdown, Form } from "react-bootstrap";
import { MdHistoryToggleOff } from "react-icons/md";
import Link from "next/link";
import { useRouter } from "next/router";
import { BiSearch } from "react-icons/bi";
const SearchBox = () => {
  const router = useRouter();
  const [history, setHistory] = useState([]);
  const [query, setQuery] = useState();
  const [show, setShow] = useState();
  useEffect(() => {
    setShow(false);
    setQuery(router.query.q);
    setHistory(GetSearchHistory().filter((_, i) => i < 10));
  }, [router.query]);

  const HandleSearch = (e) => {
    e.preventDefault();
    if (!query && router.pathname != "/search") return;
    AppendQuery(query);
    router.push({ pathname: "/search", query: { q: query } });
  };

  const handleFocus = () => {
    if (router.pathname != "/search") router.push("/search");
    setTimeout(() => {
      document.getElementById("searchBox").focus();
    }, 10);
  };

  return (
    <>
      <Form onSubmit={HandleSearch}>
        <Dropdown
          onToggle={(e) => setShow(e)}
          show={show}
          className="custom-dropdown flex-grow-1"
        >
          <Dropdown.Toggle variant={`transparent w-100 shadow-none p-0`}>
            <Container className="search-box flex-grow-1 d-flex py-1 align-items-center mx-0">
              <BiSearch />
              <Form.Control
                id="searchBox"
                value={query}
                onFocus={handleFocus}
                onChange={(e) =>
                  router.push({
                    pathname: "/search",
                    query: { q: e.target.value },
                  })
                }
                className="flex-grow-1 bg-transparent shadow-none border-0 w-auto font-md"
                placeholder="Search For Products,Tag & Rental"
              />
            </Container>
          </Dropdown.Toggle>

          <Dropdown.Menu className="auto-suggest">
            {history.length > 0 ? (
              <div className="pt-3 pb-2 px-3">
                <ul className="p-0">
                  {history.map((x) => (
                    <li className="py-2 border-bottom border-light">
                      <Link href={{ pathname: "/search", query: { q: x } }}>
                        <a
                          href={{ pathname: "/search", query: { q: x } }}
                          className="text-grey font-md mb-2"
                        >
                          <MdHistoryToggleOff className="me-3" />
                          <span className="text-dark">{x}</span>
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </Dropdown.Menu>
        </Dropdown>
      </Form>
    </>
  );
};

export default SearchBox;

const GetSearchHistory = () =>
  JSON.parse(localStorage.getItem("search-history") || "[]");

const AppendQuery = (t) =>
  t
    ? localStorage.setItem(
        "search-history",
        JSON.stringify([t, ...GetSearchHistory()])
      )
    : null;
