import Link from "next/link";
import { FiShoppingCart } from "react-icons/fi";
import { useSelector } from "react-redux";
const NavCart = () => {
  const cart = useSelector((s) => s.cart);
  return (
    <div className="nav-cart">
      <Link href="/cart">
        <a href="/cart">
          <div className="d-flex text-dark align-items-end">
            <div className="position-relative">
              <FiShoppingCart className="fs-3 text-secondary" />
              <span
                className="position-absolute fw-bolder bg-white rounded-circle font-md text-center"
                style={{
                  top: 0,
                  left: "50%",
                  transform: "translateX(-27%)",
                  height: "13px",
                  width: "13px",
                  // lineHeight: "20px",
                }}
              >
                {cart?.items?.length || "..."}
              </span>
            </div>
            <p className="font-lg fw-600 ms-2 mb-0 d-none d-md-block">Cart</p>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default NavCart;
