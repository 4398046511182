import dynamic from "next/dynamic";
import { Col, Row } from "react-bootstrap";
import SearchBox from "./search";
import Link from "next/link";
import NavUser from "./user";
import NavCart from "./cart";
import { FiHeart } from "react-icons/fi";
const NavAction = () => {
  return (
    <>
      <Col md="auto flex-grow-1" sm="auto" id="search-col">
        <SearchBox />
      </Col>
      <Col xs="auto d-none d-md-block mx-3">
        <Link href="/wishlist">
          <FiHeart role="button" className="fs-6" />
        </Link>
      </Col>
      <Col xs="auto d-none d-md-block me-2">
        <NavUser />
      </Col>

      <Col xs="auto" id="cart-col">
        <NavCart />
      </Col>
    </>
  );
};

export default NavAction;
